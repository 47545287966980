import React, { useContext, useState } from "react";
import { useRef } from "react";
import { ThemeContext } from "../../context";
import "./contact.css"
import emailjs from '@emailjs/browser';

const Contact = () => {
    const formRef = useRef();
    const theme = useContext(ThemeContext);
    const darkMode = theme.state.darkMode; 
    const [done, setDone] = useState(false);
    
    const handleSubmit = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_6ne8w9o', 'template_iyulkwr', formRef.current, 'Ktbv8ySoQFCO3oL3Q')
      .then((result) => {
          console.log(result.text);
          setDone(true);
      }, (error) => {
          console.log(error.text);
      });
    }

    return(
        <div className="c">
            <div className="c-bg"></div>
            <div className="c-wrapper">
                <div className="c-left">
                <h1 className="c-title">Let's discuss your project</h1>
                <div className="c-info">
                    <div className="c-info-item">
                        <img src={require("../../img/skype.png")} style={{width:30, height:30, marginRight:10,}} />
                         Pietrogar_2                        
                    </div>
                    {/* <div className="c-info-item">
                        Contact@gmail.com
                    </div>
                    <div className="c-info-item">
                    <img src={require("../../img/location.jpeg")} style={{width:30, height:30, marginRight:10,}} />
                        From Chile, South America.
                    </div> */}
                </div>
                </div>
                <div className="c-right">
                    <p className="c-desc">
                        <b>What's your project?</b> Contact me to build your app.
                    </p>
                    <form ref={formRef} onSubmit={handleSubmit}>
                        <input style={{backgroundColor : darkMode && "#333" }} type="text" placeholder="Name" name="username"/>
                        <input style={{backgroundColor : darkMode && "#333" }} type="text" placeholder="Subject" name="subject"/>
                        <input style={{backgroundColor : darkMode && "#333" }} type="text" placeholder="Email" name="email"/>
                        <textarea style={{backgroundColor : darkMode && "#333" }} rows="5" placeholder="Message" name="message" />
                        <button>Submit</button>
                        {done && 'Thank you for your message. Will be answered shortly.' }
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Contact;