import Intro from './components/intro/Intro';
import About from './components/about/About';
import ProductList from './components/productList/ProductList';
import Contact from './components/contact/Contact';
import Toggle from './components/toggle/Toggle';
import { useContext, useState } from 'react';
import { ThemeContext } from './context';
import { createTheme, ThemeProvider } from '@mui/material';


function App() {
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;  

  const [mode, setMode] = useState('light');
  const darkTheme = createTheme({
    palette:{
      mode: mode,
    },
  });

  return (
    <ThemeProvider theme={darkTheme} >
    <div style={{backgroundColor: darkMode? '#222' : 'white', color: darkMode && 'white' }}> 
      <Toggle setMode={setMode} mode={mode}  />
      <Intro  />
      <About  />
      <ProductList  />
      <Contact  />
      
    </div>
    </ThemeProvider>
  );
}

export default App;
