import React from 'react'
import { Box, Modal, styled } from '@mui/material';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 610,
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const Image = styled('img')({
    // height: '41px',
    objectFit: 'contain',
    // resizeMode: 'contain',
    // alignSelf: 'center',
  });

const ImageModal = ({open, setOpen, source}) => {
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
  
    return (
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
            <Box sx={{...style, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Image src={source} alt="Modal content" width={550} height={450} />
            </Box>
        </Modal>
      </div>
    );
}

export default ImageModal
