import React from 'react';
import './intro.css';

const Intro = () => {
    return (
        <div className='intro'>
            <div className='intro-left'>
                <div className='intro-left-wrapper'>
                        <h2 className='intro-saludo' >Hello, my name is:</h2>
                        <h1 className='intro-nombre'>Pietro</h1>
                        <div className='intro-title'>
                            <div className='intro-title-wrapper'>
                                <div className='intro-title-item'>React Developer</div>                                
                                <div className='intro-title-item'>AWS Certified</div>                                
                                <div className='intro-title-item'>UI/UX Designer</div>
                                <div className='intro-title-item'>IA Specialist</div>
                                <div className='intro-title-item'>Web security</div>
                            </div>
                        </div>
                        <p className='intro-description'>
                            I use my knowledge to create applications effectively and efficiently.
                        </p>
                        </div>
                        
            </div>
             
            <div className='intro-right'>
                <div className='intro-bg'>
                <img src='https://miro.medium.com/max/1187/1*0FqDC0_r1f5xFz3IywLYRA.jpeg' alt='' className='intro-image'/>
                </div>
             </div>
        </div>
    );

}

export default Intro;